export const IconChevron = ({ width = "7", height = "12", fill = "#A3A9B6" }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 7 12'>
            <path
                fill={fill}
                d='M.595.97a.75.75 0 000 1.06L4.565 6 .594 9.97a.75.75 0 101.06 1.06L6.42 6.265a.375.375 0 000-.53L1.655.97a.75.75 0 00-1.06 0z'
            ></path>
        </svg>
    );
};
