import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import search from "./assets/search.svg";
import downArrow from "./assets/downArrow.svg";

const NavBarHorizontal = ({ user }) => {
    const navigate = useNavigate();
    const logoutHandler = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const crearSubasta = () => {
        navigate("/subastas/crear");
    };

    const initial = user?.name ? user?.name.charAt(0).toUpperCase() : "?";
    return (
        <Container>
            <BotonCrearSubasta onClick={crearSubasta}>
                <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z'
                        fill='white'
                    />
                </svg>
                Crear subasta
            </BotonCrearSubasta>
            <ContentInput>
                <Icon src={search} />
                <InputField type='text' placeholder='Busca lo que desees' />
            </ContentInput>
            <FlexRow>
                <ContentImageProfile>
                    <ImageProfile backgroundColor={"#003049"}>{initial}</ImageProfile>
                    <StatusConnectUser status={true} />
                </ContentImageProfile>
                <FlexColumn>
                    <NombreApellido>
                        {user?.name?.toUpperCase()} {user?.last_name?.toUpperCase()}
                    </NombreApellido>
                    <Cargo>{user?.role?.toUpperCase()}</Cargo>
                </FlexColumn>
                <Icon className='down-arrow' src={downArrow} />

                <Dropdown className='dropdown'>
                    {/* <Link to={"/soporte"}>Soporte</Link> */}
                    {/* <Link to={"/ajuste"}>Ajuste</Link> */}
                    <button type='button' onClick={logoutHandler}>
                        Cerrar sesión
                    </button>
                </Dropdown>
            </FlexRow>
        </Container>
    );
};
const ImageProfile = styled.div`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 16px;
    background-color: ${(props) => props.backgroundColor};
    font-family: Inter;
`;
const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
`;

const NombreApellido = styled.div`
    color: var(--Neutral-Black-Black-800, #1a1c21);
    /* Text M/Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;
`;

const Cargo = styled.div`
    color: #667085;

    /* Text S/Medium */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 150% */
    letter-spacing: 0.06px;
`;

const StatusConnectUser = styled.div`
    background-color: ${(prop) => (prop.status ? "green" : "red")};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: relative;
    top: -15px;
    left: 19px;
    border: solid 2px white;
    box-sizing: content-box;
`;

const ContentImageProfile = styled.div`
    width: 32px;
    height: 32px;
    margin-right: 5px;
`;

const FlexRow = styled.div`
    width: auto;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &:hover .down-arrow {
        transform: rotate(180deg);
    }

    &:hover .dropdown {
        max-height: 300px;
        padding-bottom: 14px;
    }
`;

const Container = styled.div`
    width: -webkit-fill-available;
    height: 40px;
    padding: 16px 24px 16px 24px;
    box-sizing: content-box;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 32px;
`;

const Icon = styled.img`
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease-in;
`;

const InputField = styled.input`
    border: none;
    text-decoration: none;
    outline: none;
    /* Text M/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;
    width: 90%;
    height: 38px;
`;
const ContentInput = styled.div`
    display: flex;
    width: 450px;
    height: 40px;
    padding: 8px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid var(--Neutral-Gray-Gray-100, #e0e2e7);
    background: var(--Neutral-White, #fff);
    color: var(--Neutral-Gray-Gray-400, #858d9d);
`;

const BotonCrearSubasta = styled.div`
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #14213d;
    color: var(--Neutral-White, var(--Blanco, #fff));
    /* Text M/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    &:hover {
        background-color: #0c84f3;
    }
`;

const Dropdown = styled.div`
    position: absolute;
    left: 0;
    bottom: -38%;
    transform: translate(0, 100%);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    padding: 0 10px;
    width: 100%;
    max-height: 0;
    background-color: #ffffff;
    border-radius: 0 0 16px 16px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    a,
    button {
        display: block;
        width: fit-content;
        background: none;
        border: none;
        outline: none !important;
        text-decoration: none;
        font-size: 14px;
        font-weight: 600;
        color: #17223c;
        transition: color 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            color: #0c84f3;
        }
    }
`;

export default NavBarHorizontal;
