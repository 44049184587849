import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import "./UserDetails.scss";
import CardUser from "./CardUser.jsx";
import { IconChevron, IconPlus } from "../../svgs";
import { getAllUsersPagination } from "../../Utils/endpoints.js";

const Usuarios = () => {
    const [totalPages, setTotalPages] = useState(1);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);

    const getAllUsers = async (page) => {
        getAllUsersPagination({
            page,
            perPage: 30,
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.usuarios);
                setTotalPages(res.data.totalPages);
            }
        });
    };

    useEffect(() => {
        getAllUsers(page);
    }, [page]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    return (
        <Container>
            <h1>Usuarios</h1>
            <div
                className='section-header'
                style={{
                    width: "100%",
                }}
            >
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>
                    <IconChevron />

                    <p>Usuarios</p>
                </article>
                <article className='actions-page'>
                    <Link to={"/usuarios/crear"} className='main-btn'>
                        <IconPlus />
                        Agregar Usuario
                    </Link>
                </article>
            </div>

            <ScrollContainer>
                {users?.length > 0 ? (
                    users.map((user, index) => (
                        <CardUser
                            key={user.id} // Usar la id del usuario como key
                            data={user}
                        />
                    ))
                ) : (
                    <p>No hay usuarios.</p>
                )}
            </ScrollContainer>
            <Pagination>
                <Button disabled={page <= 1} onClick={() => setPage(page - 1)}>
                    Anterior
                </Button>
                <PageInfo>
                    Página {page} de {totalPages}
                </PageInfo>
                <Button disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)}>
                    Siguiente
                </Button>
            </Pagination>
        </Container>
    );
};

const Container = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: space-between;
    justify-content: flex-start;
    padding: 0 24px 32px;
`;

const ScrollContainer = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: 50vh;
    background-color: #ffffff;
    border: solid 1.5px #e0e2e7;
    border-radius: 8px;
    min-height: 60vh;
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: Inter;
    width: -webkit-fill-available;
`;

const Button = styled.button`
    background-color: #003366;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;

    &:disabled {
        background-color: #e0e2e7;
        cursor: not-allowed;
    }
`;

const PageInfo = styled.span`
    margin: 0 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
`;

export default Usuarios;
