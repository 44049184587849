import React, { useState, useEffect } from "react";
import styled from "styled-components";

const DropdownContainer = styled.div`
    position: relative;
    width: 210px;
    min-height: 35px;
`;

const DropdownHeader = styled.div`
    padding: 8px;
    //   border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #495057;

    min-height: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Centra verticalmente los elementos */
    &:hover {
        background-color: #f0f0f0;
    }
`;

const ArrowIcon = styled.div`
    font-size: 18px;
`;

const DropdownList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;
    width: max-content;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    list-style: none;
    z-index: 1;
    width: 190px;
    max-height: max-content;
    //   overflow-y: auto;
    &::-webkit-scrollbar {
        height: 4px;
        width: 3px;
        background-color: transparent !important;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #003049 !important;
        border-radius: 8px;
    }
`;

const DropdownItem = styled.li`
    padding: 8px;
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const DropDown = ({ options = [], selectedOption, setSelectedOption, Containerwidth }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [color, setColor] = useState(selectedOption ? true : false);

    useEffect(() => {
        setColor(selectedOption ? true : false);
    }, [selectedOption]);

    const handleSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <DropdownContainer
            style={{
                width: Containerwidth ? Containerwidth : "150px",
            }}
        >
            <DropdownHeader color={color} onClick={() => setIsOpen(!isOpen)}>
                {selectedOption ? (selectedOption === "Todos" ? "Estado" : selectedOption) : "Estado"}
                <ArrowIcon>{isOpen ? "▲" : "▼"}</ArrowIcon>
            </DropdownHeader>
            {isOpen && (
                <DropdownList
                    style={{
                        width: Containerwidth === "100%" ? "-webkit-fill-available" : "150px",
                    }}
                >
                    {options.map((option, index) => (
                        <DropdownItem key={index} onClick={() => handleSelect(option)}>
                            {option}
                        </DropdownItem>
                    ))}
                </DropdownList>
            )}
        </DropdownContainer>
    );
};

export default DropDown;
