import React, { useState, useEffect } from "react";

export const CountDown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeLeft]);

    return (
        <>
            {timeLeft.days !== undefined ? (
                timeLeft.days === 0 ? (
                    <span suppressHydrationWarning style={{ fontSize: "15px" }}>
                        {String(timeLeft.hours).padStart(2, "0")} horas, {String(timeLeft.minutes).padStart(2, "0")} min,{" "}
                        {String(timeLeft.seconds).padStart(2, "0")} seg
                    </span>
                ) : (
                    <span suppressHydrationWarning>
                        <b>
                            {timeLeft.days} días, {String(timeLeft.hours).padStart(2, "0")}:{String(timeLeft.minutes).padStart(2, "0")}:
                            {String(timeLeft.seconds).padStart(2, "0")}
                        </b>
                    </span>
                )
            ) : (
                <span>¡Tiempo alcanzado!</span>
            )}
        </>
    );
};
