export const formatDateTime = (isoString) => {
    // Crear un objeto Date a partir del string ISO
    const date = new Date(isoString);

    // Extraer los componentes de la fecha
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Los meses empiezan desde 0
    const year = date.getFullYear();

    // Extraer los componentes de la hora
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Formatear la fecha y hora
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    // Combinar fecha y hora en el formato deseado
    return `${formattedDate} ${formattedTime}`;
};

export const formatNumber = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
