export const IconDownload = ({ width = "800", height = "800", fill = "#010002" }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            version='1.1'
            viewBox='0 0 288.929 288.929'
            xmlSpace='preserve'
        >
            <path
                fill={fill}
                d='M181.969 152.054c-5.167-4.324-12.869-3.655-17.198 1.507l-8.104 9.654V12.205C156.667 5.466 151.206 0 144.462 0s-12.205 5.466-12.205 12.205v151.016l-8.099-9.654c-4.33-5.156-12.02-5.842-17.198-1.507-5.162 4.34-5.836 12.037-1.501 17.198l29.659 35.343c.098.114.223.196.326.31.359.397.751.767 1.159 1.104.239.207.468.413.718.598.451.326.93.604 1.43.865.245.136.479.283.734.402.571.256 1.175.441 1.784.604.212.054.408.141.626.19a12.22 12.22 0 002.573.283c.887 0 1.746-.103 2.578-.283.212-.049.413-.136.625-.19.609-.169 1.213-.348 1.784-.604.256-.12.49-.267.734-.402.495-.261.979-.544 1.43-.865.25-.185.479-.392.718-.598.408-.343.794-.707 1.153-1.104.103-.114.228-.19.326-.31l29.659-35.343c4.336-5.167 3.661-12.863-1.506-17.204z'
            ></path>
            <path
                fill={fill}
                d='M230.584 288.929H58.35c-13.092 0-23.742-10.65-23.742-23.736V92.954c0-13.086 10.65-23.736 23.742-23.736h44.247c6.739 0 12.205 5.466 12.205 12.205s-5.466 12.211-12.205 12.211H59.019v170.885h170.89V93.628h-42.593c-6.744 0-12.205-5.466-12.205-12.211 0-6.739 5.461-12.205 12.205-12.205h43.262c13.092 0 23.742 10.65 23.742 23.736v172.244c.005 13.087-10.644 23.737-23.736 23.737z'
            ></path>
        </svg>
    );
};
