import { Route, Routes } from "react-router-dom";

// Pages
import DashboardAux from "./Pages/DashboardAux";
import Login from "./Components/Login/Login";

const App = () => {
    return (
        <Routes>
            <Route path='/login' element={<Login />} />

            <Route path='/' element={<DashboardAux />} />
            <Route path='/usuarios' element={<DashboardAux />} />
            <Route path='/usuarios/crear' element={<DashboardAux />} />
            <Route path='/usuarios/:id' element={<DashboardAux />} />
            <Route path='/desarrollos' element={<DashboardAux />} />
            <Route path='/desarrollos/administrar-desarrollo' element={<DashboardAux />} />
            <Route path='/desarrollos/administrar-desarrollo/:id' element={<DashboardAux />} />
            <Route path='/desarrollos/desarrollo/:id' element={<DashboardAux />} />
            <Route path='/desarrollos/desarrollo/:id/administrar-lote' element={<DashboardAux />} />
            <Route path='/desarrollos/desarrollo/:id/administrar-lote/:id2' element={<DashboardAux />} />
            <Route path='/tareas' element={<DashboardAux />} />
            <Route path='/subastas' element={<DashboardAux />} />
            <Route path='/subastas/crear' element={<DashboardAux />} />
            <Route path='/subastas/subasta/:id' element={<DashboardAux />} />
            <Route path='/subastas/editar-subasta/:id' element={<DashboardAux />} />
        </Routes>
    );
};

export default App;
