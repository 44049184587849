import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Sidebar from "../Components/Sidebar/Sidebar";
import NavBarHorizontal from "../Components/NavBarHorizontal/NavBarHorizontal";
import DashboardPage from "./Dashboard";
import Desarrollos from "./desarrollos/Desarrollos";
import CrearDesarrollo from "./desarrollos/crear-editar/CrearDesarrollo";
import Desarrollo from "./desarrollos/desarrollo/Desarrollo";
import Lote from "./desarrollos/desarrollo/lote/Lote";
import UserDetails from "./usuarios/UserDetails";
import Usuarios from "./usuarios/Usuarios";
import CreateUser from "./usuarios/CreateUser";
import Tareas from "./tareas/Tareas";
import Subastas from "./subastas/Subastas";
import CreateSubasta from "./subastas/CreateSubasta";
import DetalleSubasta from "./subastas/DetalleSubasta";

const DashboardAux = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        const userLogin = localStorage.getItem("user");
        if (userLogin) {
            setUser(JSON.parse(userLogin));
        } else {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <>
            {user?.id ? (
                <>
                    <Sidebar />
                    <main className='dashboard'>
                        <NavBarHorizontal user={user} />
                        {location.pathname === "/" && <DashboardPage user={user} />}
                        {location.pathname === "/usuarios" && <Usuarios />}
                        {location.pathname === "/usuarios/crear" && <CreateUser />}
                        {location.pathname === `/usuarios/${params.id}` && <UserDetails />}
                        {location.pathname === "/desarrollos" && <Desarrollos />}
                        {location.pathname === "/desarrollos/administrar-desarrollo" && <CrearDesarrollo />}
                        {location.pathname === `/desarrollos/administrar-desarrollo/${params.id}` && <CrearDesarrollo />}
                        {location.pathname === `/desarrollos/desarrollo/${params.id}` && <Desarrollo />}
                        {location.pathname === `/desarrollos/desarrollo/${params.id}/administrar-lote` && <Lote />}
                        {location.pathname === `/desarrollos/desarrollo/${params.id}/administrar-lote/${params.id2}` && <Lote />}
                        {location.pathname === "/tareas" && <Tareas />}
                        {location.pathname === "/subastas" && <Subastas />}
                        {location.pathname === "/subastas/crear" && <CreateSubasta />}
                        {location.pathname === `/subastas/subasta/${params.id}` && <DetalleSubasta />}
                        {location.pathname === `/subastas/editar-subasta/${params.id}` && <CreateSubasta />}
                    </main>
                </>
            ) : null}
        </>
    );
};

export default DashboardAux;
