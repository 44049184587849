import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { formatDateTime, formatNumber } from "../../Utils/utilsfunctions";
import { getUserById } from "../../Utils/endpoints";
import { IconChevron } from "../../svgs";
import "./UserDetails.scss";

const UserDetails = () => {
    const params = useParams();
    const [user, setUser] = useState();

    const getUser = async (id) => {
        getUserById({
            id: id,
        }).then((res) => {
            if (res.status === 200) {
                setUser(res.data);
            }
        });
    };

    useEffect(() => {
        getUser(params.id);
    }, [params]);

    const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const initial = user?.name ? user?.name.charAt(0).toUpperCase() : "?";
    const backgroundColor = getRandomColor();
    return (
        <>
            {user?.id ? (
                <h1 style={{ marginLeft: 24, marginRight: 24 }}>
                    {user.name} {user.last_name}
                </h1>
            ) : null}

            <div className='section-header'>
                <article className='navigate-page' style={{ margin: "0 24px" }}>
                    <Link to={"/"}>Dashboard</Link>

                    <IconChevron />

                    <Link to={"/usuarios"}>Usuarios</Link>

                    <IconChevron />

                    {user?.id ? (
                        <p>
                            {user.name} {user.last_name}
                        </p>
                    ) : null}
                </article>
            </div>
            {user?.id ? (
                <div className='user-details-container'>
                    <div className='user-info'>
                        <ImageProfile backgroundColor={backgroundColor}>{initial}</ImageProfile>
                        <h2>
                            {user.name} {user.last_name}
                        </h2>

                        <p className={user.account_verified} style={{ width: "70%" }}>
                            Identidad{" "}
                            {user.account_verified === "sin-verificar"
                                ? "sin verificar"
                                : user.account_verified === "verificando"
                                ? "verificando"
                                : user.account_verified === "verificado"
                                ? "verificada"
                                : "error en la verificacion"}
                        </p>

                        <p className={user.cellphone_verified} style={{ width: "70%" }}>
                            Número de teléfono {user.cellphone_verified === "sin-verificar" ? "sin verificar" : "verificado"}
                        </p>

                        <p>ID: {user.id}</p>
                        <div className='user-info-details'>
                            <div className='info-item'>
                                <i className='icon'></i>
                                <span>
                                    <b>Nombre de Usuario:</b>
                                </span>
                                <span className='info-text'>{user.nickname}</span>
                            </div>
                            <div className='info-item'>
                                <i className='icon'></i>
                                <span>
                                    <b>Email:</b>
                                </span>
                                <span className='info-text'>{user.email}</span>
                            </div>
                            <div className='info-item'>
                                <i className='icon'></i>
                                <span>
                                    <b>Teléfono:</b>
                                </span>
                                <span className='info-text'>{user.cellphone}</span>
                            </div>
                            <div className='info-item'>
                                <i className='icon'></i>
                                <span>
                                    <b>Domicilio:</b>
                                </span>
                                <span className='info-text'>{user.address}</span>
                            </div>

                            <div className='info-item'>
                                <i className='icon'></i>
                                <span>
                                    <b>Tipo de Usuario:</b>
                                </span>
                                <span className='info-text'>{user.role}</span>
                            </div>
                        </div>
                    </div>
                    <div className='bidding-history'>
                        <div className='bidding-header'>
                            <h2>Historial de Pujas</h2>
                        </div>
                        <table className='bidding-table'>
                            <thead>
                                <tr>
                                    <th>Subasta</th>
                                    <th>Valor</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {user?.bidders?.map((bid, index) => (
                                    <tr key={bid.id}>
                                        <td>
                                            <Link to={`/subastas/subasta/${bid.auction.id}`}>
                                                {bid.auction.lot?.development?.name} - Lote #{bid.auction.lot.id}
                                            </Link>
                                        </td>
                                        <td>USD {formatNumber(bid?.bid_num)}</td>
                                        <td>{formatDateTime(bid.updatedAt)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : null}
        </>
    );
};
const ImageProfile = styled.div`
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    color: white;
    font-weight: bold;
    font-size: 32px;
    background-color: #019a90;
    font-family: Inter;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-bottom: 16px;
`;
export default UserDetails;
