import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import "./Subastas.scss";
import CardSubasta from "./CardSubasta.jsx";
import HeaderListCard from "./HeaderListCard.jsx";
import { IconChevron, IconPlus } from "../../svgs";
import { getAllSubastasPagination } from "../../Utils/endpoints.js";

const Subastas = () => {
    const [statusFilter, setStatusFilter] = useState();
    const [subastas, setSubastas] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const scrollContainerRef = useRef(null);

    const getAllUsers = async (page) => {
        getAllSubastasPagination({
            page,
            perPage: 30,
            status: statusFilter,
        }).then((res) => {
            if (res.status === 200) {
                setSubastas(res.data.auctions);
                setTotalPages(res.data.totalPages);
            }
        });
    };

    useEffect(() => {
        getAllUsers(page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, statusFilter]);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);

            scrollContainerRef.current.scrollTop = 0;
        }
    };

    return (
        <section className='subastas'>
            <h1>Subastas</h1>

            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>
                    <IconChevron />

                    <p>Subastas</p>
                </article>

                <article className='actions-page'>
                    <Link to={"/subastas/crear"} className='main-btn'>
                        <IconPlus />
                        Crear Subasta
                    </Link>
                </article>
            </div>

            <HeaderListCard setStatusFilter={setStatusFilter} statusFilter={statusFilter} />
            <ScrollContainer ref={scrollContainerRef}>
                {subastas?.length > 0 ? (
                    subastas.map((subasta) => <CardSubasta key={subasta.id} auction={subasta} />)
                ) : (
                    <p>No hay subastas.</p>
                )}
            </ScrollContainer>
            <Pagination>
                <Button disabled={page <= 1} onClick={() => handlePageChange(page - 1)}>
                    Anterior
                </Button>
                <PageInfo>
                    Página {page} de {totalPages}
                </PageInfo>
                <Button disabled={page >= totalPages} onClick={() => handlePageChange(page + 1)}>
                    Siguiente
                </Button>
            </Pagination>
        </section>
    );
};

const PageInfo = styled.span`
    margin: 0 10px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;
`;

const ScrollContainer = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    max-height: 70vh;
    background-color: #ffffff;
    border: solid 1.5px #e0e2e7;
    border-radius: 8px;
    min-height: 70vh;
`;

const Button = styled.button`
    background-color: #003366;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.07px;

    &:disabled {
        background-color: #e0e2e7;
        cursor: not-allowed;
    }
`;

const Pagination = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    font-family: Inter;
    width: -webkit-fill-available;
`;

export default Subastas;
