import { MapContainer, ImageOverlay, Polygon, Rectangle, FeatureGroup, TileLayer } from "react-leaflet";
import React, { useEffect, useState, useRef } from "react";
import { EditControl } from "react-leaflet-draw";
import L, { CRS } from "leaflet";

import style from "./CreateInteractiveMap.module.scss";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";

const CreateInteractiveMap = ({ imageFile, lots = null, setLotesEdited, auctionActives = null }) => {
    const mapRef = useRef(null);
    const featureGroupRef = useRef();

    const [imageBounds, setImageBounds] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [rectangles, setRectangles] = useState([]);

    const [forceKeyUpdate, setForceKeyUpdate] = useState(0);
    const [center, setCenter] = useState([0, 0]);
    const [maxBounds, setMaxBounds] = useState(null);

    const [lotesLoaded, setLotesLoaded] = useState([]);

    const handleSave = (e) => {
        e.preventDefault();

        let arrayLayers = [];

        const layerGroup = featureGroupRef.current;
        layerGroup.eachLayer((layer) => {
            arrayLayers.push({ ...layer.options.data, coordinates: layer._latlngs });
        });

        setLotesEdited(arrayLayers);
        // setLotes(arrayLayers);
    };

    useEffect(() => {
        setLotesLoaded(lots);
    }, [lots]);

    useEffect(() => {
        const image = new Image();
        const imageUrl = typeof imageFile === "string" ? imageFile : URL.createObjectURL(imageFile[0]);
        image.src = imageUrl;

        image.onload = function () {
            const imageWidth = image.width;
            const imageHeight = image.height;

            const southWest = mapRef.current.unproject([0, imageHeight], mapRef.current.getMaxZoom());
            const northEast = mapRef.current.unproject([imageWidth, 0], mapRef.current.getMaxZoom());

            const centerLat = (southWest.lat + northEast.lat) / 2;
            const centerLng = (southWest.lng + northEast.lng) / 2;

            const bounds = L.latLngBounds(southWest, northEast);

            setMaxBounds(bounds);
            setImageBounds(bounds);
            setCenter([centerLat, centerLng]);

            setBackgroundImage(image);
            setForceKeyUpdate((prevKey) => prevKey + 1);
        };

        return () => {
            image.onload = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageFile]);

    const handleDoubleClickPolygon = (lotData, coordinates) => {
        // Convertimos las coordenadas del polígono a un rectángulo

        const bounds = L.latLngBounds(coordinates);
        const newRectangle = {
            data: lotData,
            bounds: [
                [bounds.getSouthWest().lat, bounds.getSouthWest().lng],
                [bounds.getNorthEast().lat, bounds.getNorthEast().lng],
            ],
        };

        // Agregamos el nuevo rectángulo y removemos el polígono original
        setRectangles((prev) => [...prev, newRectangle]);
    };

    return (
        <>
            <button onClick={handleSave} type='button' className={style.button}>
                Guardar
            </button>

            <MapContainer
                key={forceKeyUpdate}
                ref={mapRef}
                center={center}
                zoom={25}
                scrollWheelZoom={false}
                doubleClickZoom={false}
                zoomControl={true}
                maxBounds={maxBounds}
                maxBoundsViscosity={1.0}
                crs={CRS.Simple}
                style={{ width: "100%", height: "90vh" }}
            >
                <TileLayer url='' />
                {imageBounds && <ImageOverlay url={backgroundImage.src} bounds={imageBounds} />}

                <FeatureGroup ref={featureGroupRef}>
                    {/* Renderizamos los polígonos */}
                    {lotesLoaded &&
                        lotesLoaded.map((e, i) => {
                            if (auctionActives) {
                                const auction = auctionActives.some((obj) => obj.lot.number_lot === e.number_lot);

                                if (auction) {
                                    return null;
                                }
                            }

                            if (e.coordinates === null) return null;

                            if (mapRef.current === null) return null;

                            const defaultStyle = {
                                color: e.selled === "Disponible" ? "#B8D368" : "#FC7032",
                                weight: 0,
                                fillOpacity: 0.3,
                            };

                            return (
                                <Polygon
                                    key={i}
                                    data={e}
                                    positions={
                                        typeof e.coordinates === "string"
                                            ? JSON.parse(e.coordinates)
                                                  .flat(1)
                                                  .map(([x, y]) => mapRef.current.unproject([x, y]))
                                            : e.coordinates
                                    }
                                    pathOptions={defaultStyle}
                                    eventHandlers={{
                                        dblclick: () =>
                                            handleDoubleClickPolygon(
                                                e,
                                                JSON.parse(e.coordinates)
                                                    .flat(1)
                                                    .map(([x, y]) => mapRef.current.unproject([x, y]))
                                            ),
                                    }}
                                />
                            );
                        })}

                    {auctionActives &&
                        auctionActives.map((e, i) => {
                            if (e.lot.coordinates === null) return null;

                            if (mapRef.current === null) return null;

                            const defaultStyle = {
                                color: "#023e7d",
                                weight: 0,
                                fillOpacity: 0.3,
                            };

                            const dataFormated = {
                                id: e.lot_id,
                                number_lot: e.number_lot,
                                measures: e.lot.measures,
                                coordinates: e.lot.coordinates,
                            };

                            return (
                                <Polygon
                                    key={i}
                                    data={dataFormated}
                                    positions={
                                        typeof e.coordinates === "string"
                                            ? JSON.parse(e.lot.coordinates)
                                                  .flat(1)
                                                  .map(([x, y]) => mapRef.current.unproject([x, y]))
                                            : e.lot.coordinates
                                    }
                                    pathOptions={defaultStyle}
                                    // eventHandlers={{
                                    //     dblclick: () =>
                                    //         handleDoubleClickPolygon(
                                    //             e,
                                    //             JSON.parse(e.coordinates)
                                    //                 .flat(1)
                                    //                 .map(([x, y]) => mapRef.current.unproject([x, y]))
                                    //         ),
                                    // }}
                                />
                            );
                        })}

                    {/* Renderizamos los rectángulos que se han convertido */}
                    {rectangles.map((rect, i) => (
                        <Rectangle key={i} bounds={rect.bounds} data={rect} pathOptions={{ weight: 0, fillOpacity: 0.3 }} />
                    ))}

                    <EditControl
                        position='topright'
                        draw={{
                            circle: false,
                            marker: false,
                            polyline: false,
                            circlemarker: false,
                            polygon: { shapeOptions: { stroke: false } },
                            rectangle: { shapeOptions: { stroke: false } },
                        }}
                        edit={{
                            edit: true,
                            remove: true,
                        }}
                    />
                </FeatureGroup>
            </MapContainer>
        </>
    );
};

export default CreateInteractiveMap;
