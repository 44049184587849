import { Link, useParams } from "react-router-dom";

import "./Lote.scss";
import { IconChevron } from "../../../../svgs";

const Lote = () => {
    let { id2 } = useParams();

    const createSubmit = (e) => {
        e.preventDefault();

        // const formData = new FormData(e.currentTarget);
        // const formValue = Object.fromEntries(formData);

        // console.log(formValue);
    };

    return (
        <section className='lote'>
            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>

                    <IconChevron />

                    <Link to={"/desarrollos"}>Desarrollos</Link>

                    <IconChevron />

                    <Link to={"/desarrollos/desarrollo/id"}>Desarrollo La Escondida</Link>

                    <IconChevron />

                    <p>La Escondida - Lote {id2 ? "50" : null}</p>
                </article>
                <article className='actions-page'>
                    <button type='button' className='main-btn' onClick={() => document.querySelector("#submit-button").click()}>
                        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M6.16667 12.4167C6.16667 12.8769 6.53976 13.25 7 13.25C7.46024 13.25 7.83333 12.8769 7.83333 12.4167V7.83333H12.4167C12.8769 7.83333 13.25 7.46024 13.25 7C13.25 6.53976 12.8769 6.16667 12.4167 6.16667H7.83333V1.58333C7.83333 1.1231 7.46024 0.75 7 0.75C6.53976 0.75 6.16667 1.1231 6.16667 1.58333V6.16667H1.58333C1.1231 6.16667 0.75 6.53976 0.75 7C0.75 7.46024 1.1231 7.83333 1.58333 7.83333H6.16667V12.4167Z'
                                fill='#fcf4e6'
                            />
                        </svg>
                        {id2 ? "Editar" : "Crear"} Lote
                    </button>
                </article>
            </div>

            <form onSubmit={createSubmit} method='post' autoComplete='off'>
                <div className='card-input'>
                    <h4>Información general</h4>

                    <div className='input-label'>
                        <label htmlFor='lot'>Número del Lote</label>
                        <input type='number' name='lot' id='lot' placeholder='Lote' required />
                    </div>
                    <div className='input-label'>
                        <label htmlFor='sizes'>Dimensiones del lote</label>
                        <input type='text' name='sizes' id='sizes' placeholder='Dimensiones' required />
                    </div>
                </div>
                <button type='submit' id='submit-button' style={{ display: "none" }}></button>
            </form>
        </section>
    );
};

export default Lote;
