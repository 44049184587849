import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { getAuctionById } from "../../Utils/endpoints";
import { CountDown } from "../../Components/CountDown/CountDown";
import { formatDateTime, formatNumber } from "../../Utils/utilsfunctions";

const DetalleSubasta = () => {
    const params = useParams();
    const [auction, setAuction] = useState();

    const getActuion = async (id) => {
        getAuctionById({
            id: id,
        }).then((res) => {
            if (res.status === 200) {
                setAuction(res.data.auction);
            }
        });
    };
    useEffect(() => {
        getActuion(params.id);
    }, [params]);

    return (
        <AuctionContainer>
            {auction?.id ? (
                <>
                    <Section>
                        <Image src={auction?.lot?.development?.imageDevelopment?.desarrollo_image} loading='lazy' alt='Lote' />
                        <Title>Lote #{auction?.lot?.number_lot}</Title>
                        <Subtitle>
                            Desarrollo: <b>{auction?.lot?.development?.name}</b>
                        </Subtitle>
                        <Subtitle>
                            Dimensiones: <b>{auction?.lot?.measures}m²</b>
                        </Subtitle>
                        <Subtitle>
                            Oferta inicial: <b>USD {auction?.initial_bid}</b>
                        </Subtitle>
                        <Subtitle>
                            Oferta más alta: <b>USD {auction?.last_bid}</b>
                        </Subtitle>
                        <Subtitle>
                            Último postor:{" "}
                            <b>
                                {auction?.bidders[0]?.user?.name || "-"} {auction?.bidders[0]?.user?.last_name || "-"}
                            </b>
                        </Subtitle>
                        <Subtitle>
                            Finaliza en: <CountDown targetDate={auction?.time_limit} />
                        </Subtitle>
                    </Section>
                    <Section style={{ flexBasis: "55%" }}>
                        <Title>Historial de ofertas</Title>
                        <br></br>
                        <BidTable>
                            <thead>
                                <tr>
                                    <th>Posición</th>
                                    <th>Usuario</th>
                                    <th>CUIT/CUIL</th>
                                    <th>Valor oferta USD</th>
                                    {/* <th>Status</th> */}
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {auction?.bidders?.length < 1 ? (
                                    <span
                                        style={{
                                            position: "absolute",
                                            textAlign: "center",
                                            alignItems: "center",
                                            width: " -webkit-fill-available",
                                            padding: "10px",
                                            fontSize: "14px",
                                            fontWeight: 600,
                                            color: "#17223c",
                                            fontFamily: "Inter",
                                        }}
                                    >
                                        Aun no hay pujas
                                    </span>
                                ) : null}
                                {auction?.bidders.map((bid, index) => (
                                    <tr key={index}>
                                        <td>#{index + 1}</td>
                                        <td>
                                            {bid.user.name} {bid.user.last_name}
                                        </td>
                                        <td>{bid.user.cuit}</td>
                                        <td>
                                            {" "}
                                            <b
                                                style={{
                                                    color: index + 1 === 1 ? "#008000" : "#f1c0c1",
                                                }}
                                            >
                                                USD {formatNumber(bid.bid_num)}
                                            </b>
                                        </td>
                                        {/* <td>{bid.status}</td> */}
                                        <td>{formatDateTime(bid.updatedAt)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </BidTable>
                    </Section>
                </>
            ) : null}
        </AuctionContainer>
    );
};
const AuctionContainer = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f8f9fa;
`;

const Section = styled.div`
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 0 10px;
`;

const Image = styled.img`
    width: 355px;
    height: 200px;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    font-size: 18px;
    color: #333;
`;

const Subtitle = styled.p`
    font-size: 16px;
    color: #666;
    margin: 5px 0;
`;

const BidTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    th,
    td {
        border-bottom: 1px solid #eee;
        padding: 8px;
        text-align: left;
    }

    th {
        background-color: #f0f0f0;
    }
`;
export default DetalleSubasta;
