import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { IconClose, IconLinkTo, IconChevron } from "../../svgs";
import { getAllTasks, updateTask } from "../../Utils/endpoints";
import { Popup } from "../../Components/Popup";
import DropDown from "../subastas/DropDown";
import "./Tareas.scss";

const statusOption = ["En proceso", "Completada", "Pendiente"];

const Tareas = () => {
    const [tasks, setTasks] = useState([]);
    const [optionSelected, setOptionSelected] = useState(null);

    const getTasks = async (query = null) => {
        await getAllTasks(query).then((res) => {
            if (res.success) {
                setTasks(res.data);
            }
        });
    };

    useEffect(() => {
        getTasks();
    }, []);

    useEffect(() => {
        if (!optionSelected) return;
        getTasks(`status=${optionSelected}`);
    }, [optionSelected]);

    return (
        <section className='tareas'>
            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>

                    <IconChevron />

                    <p>Tareas</p>
                </article>
            </div>

            <span className='list-container__filter'>
                <p>Filtrar tareas:</p>
                <DropDown options={statusOption} selectedOption={optionSelected} setSelectedOption={setOptionSelected} />
            </span>

            <div className='list-container'>
                <table className='tareas-list'>
                    <thead>
                        <tr>
                            <th>Tarea</th>
                            <th>Usuario</th>
                            <th>Teléfono</th>
                            <th>Email</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.length === 0 ? (
                            <tr>
                                <td colSpan={6}>No se encontraron tareas</td>
                            </tr>
                        ) : (
                            tasks.map((task, i) => <ItemTask task={task} key={i} getTasks={getTasks} />)
                        )}
                    </tbody>
                </table>
            </div>
        </section>
    );
};

export default Tareas;

const ItemTask = ({ task, getTasks }) => {
    const { status, taskDescription } = task.task;
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <tr>
                <td>{taskDescription}</td>

                <td>
                    {task.bidder?.user.name} {task.bidder?.user.last_name}{" "}
                    {task.bidder?.user.id && (
                        <Link to={`/usuarios/${task.bidder?.user.id}`}>
                            <IconLinkTo height='17' width='17' style={{ marginLeft: "10px", cursor: "pointer" }} />
                        </Link>
                    )}
                </td>

                <td>
                    <a href={`tel:+54${task.bidder?.user.cellphone}`}>{task.bidder?.user.cellphone}</a>
                </td>

                <td>
                    <a href={`mailto:${task.bidder?.user.email}`}>{task.bidder?.user.email}</a>
                </td>

                <td>
                    <span className={`${status === "En proceso" ? "proceso" : status.toLowerCase()}`}>{status}</span>
                </td>

                <td>
                    <div className='btn-holder'>
                        <button type='button' onClick={handleOpen}>
                            Ver Detalle
                        </button>
                    </div>
                </td>
            </tr>

            <PopupInfoTask open={open} setOpen={setOpen} details={task.task} user={task.bidder} getTasks={getTasks} />
        </>
    );
};

const PopupInfoTask = ({ open, setOpen, details, user, getTasks }) => {
    if (open === false) return;

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateTask = async (status) => {
        await updateTask({ task_id: details.id, status }).then((result) => {
            if (result.status === 200) {
                getTasks();
                handleClose();
            }
        });
    };

    return (
        <Popup open={open} setOpen={setOpen}>
            <article className={`popup-details `}>
                <div className='popup-content'>
                    <button type='button' className='close-btn' onClick={handleClose}>
                        <IconClose fill='#17223c' />
                    </button>
                    <h2>Detalles</h2>
                    <Link to={`/subastas/subasta/${details.auction_id}`}>
                        <p>Subasta id: {details.auction_id}</p>
                    </Link>
                    <p>Desarrollo: {details.auction.lot.development.name}</p>
                    <p>Número de lote: {details.auction.lot.number_lot}</p>
                    <p>Puja ganadora: {details.auction.last_bid}</p>
                    <br />
                    <h3 style={{ display: "flex", gap: "10px" }}>
                        Usuario{" "}
                        {user?.user.id && (
                            <Link to={`/usuarios/${user?.user.id}`}>
                                <IconLinkTo height='17' width='17' style={{ cursor: "pointer" }} />
                            </Link>
                        )}
                    </h3>
                    <br />
                    <p>Nombre: {user?.user.name}</p>
                    <p>Apellido: {user?.user.last_name}</p>
                    <p>Email: {user?.user.email}</p>
                    <p>Número de teléfono: {user?.user.cellphone}</p>

                    <div className='actions-popup-task'>
                        <p>Actualizar tarea:</p>

                        <span>
                            <button
                                type='button'
                                style={{ backgroundColor: "#0d894f", color: "#e7f4ee" }}
                                className='actions-popup-task__button'
                                onClick={() => handleUpdateTask("Completada")}
                            >
                                Completada
                            </button>

                            <button
                                type='button'
                                style={{ backgroundColor: "#ff8800", color: "#fdf1e8" }}
                                className='actions-popup-task__button'
                                onClick={() => handleUpdateTask("En proceso")}
                            >
                                En proceso
                            </button>

                            <button
                                type='button'
                                style={{ backgroundColor: "#e41150", color: "#fdf1e8" }}
                                className='actions-popup-task__button'
                                onClick={() => handleUpdateTask("Pendiente")}
                            >
                                Pendiente
                            </button>
                        </span>
                    </div>
                </div>
            </article>
        </Popup>
    );
};
