import { NavLink } from "react-router-dom";
import "./Sidebar.scss";

const Sidebar = () => {
    return (
        <aside className='sidebar'>
            <div className='group'>
                <NavLink to={"/"}>Inicio</NavLink>
                <NavLink to={"/tareas"}>Tareas</NavLink>
                <NavLink to={"/usuarios"}>Usuarios</NavLink>
                <NavLink to={"/desarrollos"}>Desarrollos</NavLink>
                <NavLink to={"/subastas"}>Subastas</NavLink>
            </div>
        </aside>
    );
};

export default Sidebar;
