import { Link } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";

import DragDrop from "../../Components/DragDrop/DragDrop.jsx";
import { IconChevron } from "../../svgs/IconChevron.jsx";
import "./UserDetails.scss";

const CreateUser = ({ openModal }) => {
    const [imageFile, setImageFile] = useState();
    const createSubmit = (e) => {
        e.preventDefault();

        // const formData = new FormData(e.currentTarget);
        // const formValue = Object.fromEntries(formData);
    };

    return (
        <Container>
            <h1>Usuarios</h1>
            <div
                className='section-header'
                style={{
                    width: "100%",
                    marginTop: "32px",
                }}
            >
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>

                    <IconChevron />

                    <p>Usuarios</p>

                    <IconChevron />

                    <p>Crear usuario</p>
                </article>
            </div>
            <form className='form' onSubmit={createSubmit} method='post' autoComplete='off'>
                <FlexRow>
                    <Content>
                        <div className='input-label'>
                            <label htmlFor='name'>Foto de perfil</label>
                            <DragDrop
                                files={imageFile}
                                setFiles={setImageFile}
                                text={"Arrastrá y soltá la imagen"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                            />
                        </div>
                    </Content>

                    <ContentForm>
                        <div className='input-label'>
                            <label htmlFor='name'>Nombre</label>
                            <input type='text' name='name' id='name' placeholder='Nombre' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Apellido</label>
                            <input type='text' name='lastname' id='lastname' placeholder='Apellido' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Nombre de usuario</label>
                            <input type='text' name='username' id='username' placeholder='Nombre de usuario' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Email</label>
                            <input type='email' name='email' id='email' placeholder='ejemplo@email.com' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Contraseña</label>
                            <input type='password' name='password' id='password' placeholder='Contraseña' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>CUIT/CUIL</label>
                            <input type='number' name='cuit' id='cuit' placeholder='CUIT/CUIL' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Teléfono Fijo</label>
                            <input type='number' name='phone' id='phone' placeholder='Teléfono fijo' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Teléfono Celular</label>
                            <input type='number' name='phone2' id='phone2' placeholder='Teléfono celular' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Dirección</label>
                            <input type='text' name='address' id='address' placeholder='Dirección' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Provincia</label>
                            <input type='text' name='province' id='province' placeholder='Provincia' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Localidad</label>
                            <input type='text' name='localidad' id='localidad' placeholder='Localidad' required />
                        </div>
                        <div className='input-label'>
                            <label htmlFor='name'>Código postal</label>
                            <input type='number' name='postalCod' id='postalCod' placeholder='Código postal' required />
                        </div>

                        <FlexRow
                            style={{
                                marginTop: "24px",
                                justifyContent: "flex-end",
                                gap: "16px",
                            }}
                        >
                            {/* <BtnAction
                                style={{
                                    backgroundColor: '#e0e2e7',
                                    color: '#14213d',
                                }}
                            >
                                Cancelar
                            </BtnAction> */}
                            <BtnAction
                                type='submit'
                                id='submit-button'
                                // style={{ display: 'none' }}
                            >
                                Crear usuario
                            </BtnAction>
                        </FlexRow>
                    </ContentForm>
                </FlexRow>
            </form>
        </Container>
    );
};
const BtnAction = styled.button`
    outline: none;
    border: none;
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #14213d;
    color: var(--Neutral-White, var(--Blanco, #fff));
    /* Text M/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;
    width: 200px;
    cursor: pointer;
    @media only screen and (min-width: 0px) and (max-width: 500px) {
        width: 100%;
    }
`;

const Content = styled.div`
    width: 30%;
    @media only screen and (min-width: 0px) and (max-width: 750px) {
        width: 100%;
    }
`;
const ContentForm = styled.div`
    width: 60%;
    @media only screen and (min-width: 0px) and (max-width: 750px) {
        width: 100%;
    }
`;

const Container = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: space-between;
    justify-content: flex-start;
    padding: 0 24px 32px;
`;

const FlexRow = styled.div`
    width: -webkit-fill-available;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
`;

export default CreateUser;
