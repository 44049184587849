export const IconEditDoc = ({ width = "14", height = "14", stroke = "#000" }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height}
            fill='none'
            stroke={stroke}
            strokeWidth='3'
            viewBox='0 0 64 64'
        >
            <path d='M45.56 46.83L45.56 56.26 7.94 56.26 7.94 20.6 19.9 7.74 45.56 7.74 45.56 21.29'></path>
            <path d='M19.92 7.74L19.9 20.6 7.94 20.6'></path>
            <path d='M13.09 47.67L31.1 47.67'></path>
            <path d='M13.09 41.14L29.1 41.14'></path>
            <path d='M13.09 35.04L33.1 35.04'></path>
            <path d='M13.09 28.94L39.1 28.94'></path>
            <path d='M34.45 43.23l.15 4.3a.49.49 0 00.62.46l4.13-1.11a.54.54 0 00.34-.23l18.07-24.44a1.23 1.23 0 00-.26-1.72l-3.14-2.34a1.22 1.22 0 00-1.72.26L34.57 42.84a.67.67 0 00-.12.39z'></path>
            <path d='M50.2 21.7L55.27 25.57'></path>
        </svg>
    );
};
