export const IconClose = ({ width = "14", height = "14", fill = "#FCF4E6", ...props }) => {
    return (
        <svg {...props} xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 14 14'>
            <path
                fill={fill}
                d='M13.69.32a1.057 1.057 0 00-1.497 0L7 5.504 1.807.31A1.059 1.059 0 00.31 1.807L5.503 7 .31 12.193A1.06 1.06 0 001.06 14a1.059 1.059 0 00.748-.31L7 8.497l5.193 5.193a1.06 1.06 0 001.726-.344 1.059 1.059 0 00-.23-1.153L8.498 7l5.193-5.193a1.064 1.064 0 000-1.486z'
            ></path>
        </svg>
    );
};
