import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

import "./CrearEditarDesarrollo.scss";
import "react-quill/dist/quill.snow.css";
import { Popup } from "../../../Components/Popup";
import FeaturesCheckboxes from "./FeaturesCheckboxes";
import DragDrop from "../../../Components/DragDrop/DragDrop";
import { IconPlus, IconDownload, IconChevron } from "../../../svgs";
import CustomCircularProgress from "../../../Components/LodingSpiner/CustomCircularProgress";
import CreateInteractiveMap from "../../../Components/CreateInteractiveMap/CreateInteractiveMap";
import { uploadToS3, createDesarrollo, getAllServices, getDevelopmentsCrm, getDevelopmentsCrmDetail } from "../../../Utils/endpoints";

const CrearDesarrollo = () => {
    const [imageFile, setImageFile] = useState();
    const [bannerFile, setBannerFile] = useState();
    const [galleryFiles, setGalleryFiles] = useState();
    const [documentFile, setDocumentFile] = useState();
    const [blueprintFile, setBlueprintFile] = useState();
    const [selectServices, setSelectServices] = useState([]);

    const [lotesEdited, setLotesEdited] = useState();
    const [services, setServices] = useState([]);
    const [lotes, setLotes] = useState([]);
    const navigate = useNavigate();
    let { id } = useParams();

    const [loadFromCrm, setLoadFromCrm] = useState(false);
    const [developments, setDevelopments] = useState([]);

    const handleOpenLoad = () => {
        setLoadFromCrm(true);
        getDevelopments();
    };

    async function urlToFile(url, filename, mimeType) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], filename, { type: mimeType });
    }

    const handleSelectDevelopment = async (e) => {
        setLoadFromCrm(false);

        let detailDevelopment = await getDevelopmentsCrmDetail({ id: e.id }).then((response) => response.data);

        let bluePrint = await urlToFile(detailDevelopment.plane, `${Date.now()}.png`, "image/png");
        let logoDevelopment = await urlToFile(detailDevelopment.logo, `${Date.now() * Math.PI}.svg`, "image/svg+xml");

        setBlueprintFile([bluePrint]);
        setImageFile([logoDevelopment]);

        // Actualizar otros campos del formulario según los detalles obtenidos
        document.getElementById("name").value = detailDevelopment.name;
        document.getElementById("location").value = detailDevelopment.locality;
        document.getElementById("locationCoordinates").value = detailDevelopment.coordinates;
        document.getElementById("lotes").value = detailDevelopment.totLot;

        setDescriptionValue(detailDevelopment.description);

        setLotes(detailDevelopment.lotes);
    };

    const [descriptionValue, setDescriptionValue] = useState("");

    const getServices = async () => {
        const result = await getAllServices();
        setServices(result.data);
    };

    const getDevelopments = async () => {
        await getDevelopmentsCrm().then((result) => setDevelopments(result.data));
    };

    useEffect(() => {
        getServices();
    }, []);

    const createSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;

        let imageFileUrl;
        let galleryFilesUrls = [];
        let documentFileUrl;
        let blueprintFileUrl;
        let bannerFileUrl;

        const cargarImagenes = async () => {
            const uploadPromises = [];

            if (imageFile) {
                const formData = new FormData();
                formData.append("file", imageFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            imageFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            if (galleryFiles?.length > 0) {
                for (let file of galleryFiles) {
                    const formData = new FormData();
                    formData.append("file", file);
                    uploadPromises.push(
                        uploadToS3(formData).then((response) => {
                            if (response.status === 200) {
                                galleryFilesUrls.push(response.data.photoUrl);
                            }
                        })
                    );
                }
            }

            if (documentFile) {
                const formData = new FormData();
                formData.append("file", documentFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            documentFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            if (blueprintFile) {
                const formData = new FormData();
                formData.append("file", blueprintFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            blueprintFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            if (bannerFile) {
                const formData = new FormData();
                formData.append("file", bannerFile[0]);
                uploadPromises.push(
                    uploadToS3(formData).then((response) => {
                        if (response.status === 200) {
                            bannerFileUrl = response.data.photoUrl;
                        }
                    })
                );
            }

            await Promise.all(uploadPromises);
        };

        await cargarImagenes();

        const dataBody = {
            name: form.elements.name.value,
            description: descriptionValue,
            province: form.elements.location.value,
            locality: form.elements.location.value,
            address: "",
            coordinates: form.elements.locationCoordinates.value,
            services: selectServices,
            cant_lots: form.elements.lotes.value,
            gallery: galleryFilesUrls,
            documentation: documentFileUrl,
            desarrollo_plane: blueprintFileUrl,
            logo: imageFileUrl,
            banner: bannerFileUrl,
            lotes: lotesEdited,
        };

        createDesarrollo(dataBody).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Se actualizó con éxito",
                    confirmButtonText: "Listo",
                    confirmButtonColor: "#003049",
                    customClass: {
                        title: "my-custom-title-class",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/desarrollos");
                    }
                });
            }
        });
    };

    return (
        <section className='crear-editar-desarrollo'>
            <h1>{id ? "Editar" : "Crear"} Desarrollo</h1>

            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to={"/"}>Dashboard</Link>

                    <IconChevron />

                    <Link to={"/desarrollos"}>Desarrollos</Link>

                    <IconChevron />

                    <p>{id ? "Editar" : "Crear"} Desarrollo</p>
                </article>
                <article className='actions-page'>
                    <button type='button' className='main-btn' onClick={handleOpenLoad}>
                        <IconDownload height='16' width='16' fill='#FCF4E6' />
                        Traer del CRM
                    </button>
                    <button type='button' className='main-btn' onClick={() => document.querySelector("#submit-button").click()}>
                        <IconPlus />
                        {id ? "Editar" : "Crear"} Desarrollo
                    </button>
                </article>
            </div>

            <form onSubmit={createSubmit} method='post' autoComplete='off'>
                <div className='form-holder'>
                    <article className='form-col'>
                        <div className='card-input'>
                            <h4>Logo del desarrollo</h4>
                            <DragDrop
                                files={imageFile}
                                setFiles={setImageFile}
                                text={"Arrastrá y soltá la imagen"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                                name='logo'
                            />
                        </div>

                        <div className='card-input'>
                            <h4>Banner del desarrollo</h4>
                            <DragDrop
                                files={bannerFile}
                                setFiles={setBannerFile}
                                text={"Arrastrá y soltá la imagen"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                                name='banner'
                            />
                        </div>

                        <div className='card-input'>
                            <h4>Gallería</h4>
                            <DragDrop
                                files={galleryFiles}
                                setFiles={setGalleryFiles}
                                isMultiple={true}
                                text={"Arrastrá y soltá las imágenes"}
                                accept={"image/png, image/jpeg, image/webp"}
                                name='galeria'
                            />
                        </div>

                        <div className='card-input'>
                            <h4>Documentación</h4>
                            <DragDrop
                                files={documentFile}
                                setFiles={setDocumentFile}
                                text={"Arrastrá y soltá el documento"}
                                accept={"application/pdf"}
                                name='documentacion'
                            />
                        </div>

                        <div className='card-input'>
                            <h4>Plano</h4>
                            <DragDrop
                                files={blueprintFile}
                                setFiles={setBlueprintFile}
                                text={"Arrastrá y soltá el plano"}
                                accept={"image/png, image/jpeg, image/webp, image/svg+xml"}
                                name='plano'
                            />
                        </div>
                    </article>
                    <article className='form-col'>
                        <div className='card-input'>
                            <h4>Información general</h4>

                            <div className='input-label'>
                                <label htmlFor='name'>Nombre del desarrollo</label>
                                <input type='text' name='name' id='name' placeholder='Nombre' required />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='location'>Ubicación</label>
                                <input type='text' name='location' id='location' placeholder='La Plata' required />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='locationCoordinates'>Ubicación (Coordenadas)</label>
                                <input
                                    type='text'
                                    name='locationCoordinates'
                                    id='locationCoordinates'
                                    placeholder="34°36'47'' S 58°22'63'' O"
                                    required
                                />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='lotes'>Cantidad de lotes</label>
                                <input type='number' name='lotes' id='lotes' placeholder='0' min={1} defaultValue={1} required />
                            </div>
                            <div className='input-label'>
                                <h4>Servicios</h4>
                                <FeaturesCheckboxes
                                    setSelectServices={setSelectServices}
                                    selectServices={selectServices}
                                    getServices={getServices}
                                    features={services}
                                />
                            </div>
                            <div className='input-label'>
                                <label htmlFor='description'>Breve descripción</label>
                                <ReactQuill theme='snow' value={descriptionValue} onChange={setDescriptionValue} />
                            </div>

                            {!blueprintFile?.length < 1 && (
                                <CreateInteractiveMap
                                    imageFile={blueprintFile}
                                    setLotes={setLotes}
                                    lots={lotes}
                                    setLotesEdited={setLotesEdited}
                                />
                            )}
                        </div>
                    </article>
                </div>
                <button type='submit' id='submit-button' style={{ display: "none" }}></button>
            </form>

            <ModalSelectDevelopment
                open={loadFromCrm}
                setOpen={setLoadFromCrm}
                developments={developments}
                handleSelectDevelopment={handleSelectDevelopment}
            />
        </section>
    );
};

export default CrearDesarrollo;

const ModalSelectDevelopment = ({ open, setOpen, developments = [], handleSelectDevelopment }) => {
    if (!open) return;

    return (
        <Popup open={open} setOpen={setOpen}>
            <div className='modal_load_from_crm'>
                {developments.length === 0 ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CustomCircularProgress size={40} color='#14213d' padding='5px' />
                    </div>
                ) : (
                    developments.map((e) => (
                        <p key={e.id} onClick={() => handleSelectDevelopment(e)}>
                            {e.name}
                        </p>
                    ))
                )}
            </div>
        </Popup>
    );
};
