import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import "./Subastas.scss";
import { getAllDesarrollosPagination, getDevelopmentById, createAuction } from "../../Utils/endpoints";

const Container = styled.div`
    height: auto;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px 32px;
`;

const Thumbnail = styled.div`
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    border: 1.5px solid #e0e2e7;
    box-shadow: rgba(16, 24, 40, 0.1) 0px 1.5px 2px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Label = styled.label`
    font-weight: bold;
`;

const Input = styled.input`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Select = styled.select`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
`;

const Button = styled.button`
    background-color: #f0e7ff;
    border: none;
    padding: 10px;
    cursor: pointer;
`;

const BtnAction = styled.button`
    outline: none;
    border: none;
    display: flex;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background: #14213d;
    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.07px;
    width: 200px;
    cursor: pointer;

    @media (max-width: 500px) {
        width: 100%;
    }
`;

const FlexRow = styled.div`
    width: -webkit-fill-available;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 32px;
    flex-wrap: wrap;
`;

const formStyle = {
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "16px",
    border: "solid 1.5px #e0e2e7",
    boxShadow: "0px 1.5px 2px 0px rgba(16, 24, 40, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "8px",
};

const CreateSubasta = () => {
    const [form, setForm] = useState({
        development: "",
        lot: "",
        startDate: "",
        endDate: "",
        basePrice: "",
    });

    const [desarrollos, setDesarrollos] = useState([]);
    const [lotes, setLotes] = useState([]);
    const [featured, setFeatured] = useState(false);
    useEffect(() => {
        const fetchDesarrollos = async () => {
            try {
                const response = await getAllDesarrollosPagination({ page: 1, perPage: 999999 });
                setDesarrollos(response.data.developments); // Ajusta según la estructura de los datos devueltos
            } catch (error) {
                console.error("Error fetching desarrollos:", error);
            }
        };

        fetchDesarrollos();
    }, []);

    const handleDevelopmentChange = async (e) => {
        const { name, value } = e.target;
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
            lot: "", // Resetea el lote seleccionado cuando se cambia el desarrollo
        }));

        if (value) {
            try {
                const response = await getDevelopmentById({ id: value });
                const availableLotes = response.data.lots.filter((lot) => lot.selled === "Disponible" && lot.active === false);
                availableLotes.sort((a, b) => b.number_lot - a.number_lot);

                setLotes(availableLotes.reverse());
            } catch (error) {
                console.error("Error fetching development lots:", error);
            }
        } else {
            setLotes([]);
        }
    };
    const formatNumber = (value) => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };
    const handlePriceChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = value.replace(/\D/g, "");
        setForm((prevForm) => ({
            ...prevForm,
            [name]: formatNumber(formattedValue),
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const validateForm = () => {
        const { development, lot, startDate, endDate, basePrice } = form;
        if (!development || !lot || !startDate || !endDate || !basePrice) {
            Swal.fire("Error", "Todos los campos son obligatorios.", "error");
            return false;
        }
        if (new Date(startDate) >= new Date(endDate)) {
            Swal.fire("Error", "La fecha de inicio debe ser anterior a la fecha de finalización.", "error");
            return false;
        }
        if (isNaN(parseFloat(basePrice)) || parseFloat(basePrice) <= 0) {
            Swal.fire("Error", "El precio base debe ser un número positivo.", "error");
            return false;
        }
        return true;
    };
    const parseNumber = (value) => {
        return value.replace(/\./g, "");
    };
    const createSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        Swal.fire({
            title: "¿Estás seguro?",
            text: "¿Quieres crear esta subasta?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, crear subasta",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const parsedBasePrice = parseNumber(form.basePrice);
                    await createAuction({
                        initial_bid: parsedBasePrice,
                        time_limit: form.endDate,
                        start_date: form.startDate,
                        lot_id: form.lot,
                        featured: featured,
                    });
                    Swal.fire("¡Creada!", "La subasta ha sido creada con éxito.", "success");
                    setForm({
                        development: "",
                        lot: "",
                        startDate: "",
                        endDate: "",
                        basePrice: "",
                    });
                    setLotes([]);
                } catch (error) {
                    Swal.fire("Error", "Hubo un problema al crear la subasta.", "error");
                }
            }
        });
    };

    return (
        <Container>
            <h1>Subastas</h1>
            <div className='section-header'>
                <article className='navigate-page'>
                    <Link to='/'>Dashboard</Link>
                    <svg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M0.59467 0.96967C0.301777 1.26256 0.301777 1.73744 0.59467 2.03033L4.56434 6L0.59467 9.96967C0.301777 10.2626 0.301777 10.7374 0.59467 11.0303C0.887563 11.3232 1.36244 11.3232 1.65533 11.0303L6.4205 6.26516C6.56694 6.11872 6.56694 5.88128 6.4205 5.73484L1.65533 0.96967C1.36244 0.676777 0.887563 0.676777 0.59467 0.96967Z'
                            fill='#A3A9B6'
                        />
                    </svg>
                    <p>Subastas</p>
                </article>
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "24px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "24px", width: "300px" }}>
                    <Thumbnail style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                        <Label>Destacada</Label>
                        <Input type='checkbox' onChange={(e) => setFeatured(e.target.checked)} />
                    </Thumbnail>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "24px", width: "70%" }}>
                    <form style={formStyle}>
                        <FormGroup>
                            <Label>Desarrollo</Label>
                            <Select name='development' value={form.development} onChange={handleDevelopmentChange}>
                                <option value=''>Seleccioná desarrollo</option>
                                {desarrollos.map((dev) => (
                                    <option key={dev.id} value={dev.id}>
                                        {dev.name} {/* Ajusta según el nombre del campo del desarrollo */}
                                    </option>
                                ))}
                            </Select>
                        </FormGroup>
                        <FormGroup>
                            <Label>Lote</Label>
                            {lotes.length < 1 && form.development !== "" ? (
                                <h3>Este desarrollo no tiene lotes disponibles</h3>
                            ) : (
                                <Select name='lot' value={form.lot} onChange={handleChange}>
                                    <option value=''>Seleccioná lote</option>
                                    {lotes.map((lot) => (
                                        <option key={lot.id} value={lot.id}>
                                            {lot.number_lot} - {lot.measures}
                                        </option>
                                    ))}
                                </Select>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label>Fecha de inicio</Label>
                            <Input type='datetime-local' name='startDate' value={form.startDate} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fecha de finalización</Label>
                            <Input type='datetime-local' name='endDate' value={form.endDate} onChange={handleChange} />
                        </FormGroup>
                    </form>
                    <form style={formStyle}>
                        <FormGroup>
                            <Label>Precio base</Label>
                            <Input type='text' name='basePrice' value={form.basePrice} onChange={handlePriceChange} />
                        </FormGroup>
                    </form>
                    <FlexRow style={{ justifyContent: "flex-end", gap: "16px" }}>
                        <BtnAction type='submit' onClick={createSubmit}>
                            Crear subasta
                        </BtnAction>
                    </FlexRow>
                </div>
            </div>
        </Container>
    );
};

export default CreateSubasta;
